import {
  get, excel
} from '../utils/http';

// 辅导员获取学生列表
export function getMYPageJQGridPage(data) {
  return get('hsjc/SY_HSJCQR/GetPageList/', data);
}

// 导出学生信息
export function exportXSExcel(data) {
  return excel('hsjc/SY_HSJCQR/ExportExcel/', data);
}
